import { Notification } from '@ftdr/blueprint-components-react';
import styles from './notification.module.css';

export interface HvacNotificationProps {
  type: "success" | "error",
  text: string
  onClose: () => void
}

export const HvacNotification = ({type, text, onClose}: HvacNotificationProps) => {
    return (
      <Notification
        variant="floating"
        className={`fixed ${styles.notification}`}
        data-testid={`Hvac_Notification_${type}`}
        status={type}
        showStatusLabel={true}
        inline={false}
        onClose={onClose}
        autoCloseDelay={type === "success" ? 10 * 1000 : 0}
      >
        {text}
      </Notification>
    )
  }