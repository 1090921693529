import { Text } from '@ftdr/blueprint-components-react'
import { useAuth } from '@ftdr/use-auth'

export const WelcomeAgentHeader = () => {
  const { oidcUser } = useAuth()

  return (
    <div className="-mx-4 lg:-mx-48 -mt-10 py-10 px-4 lg:px-48 mb-8 bg-gray-200">
      <Text
        variant="heading-03"
        textTemplateKey="WELCOME_AGENT"
        textTemplateData={{
        name: `${oidcUser?.profile.name}`,
        }}
      />
      <Text textTemplateKey="HVAC_UPGRADE_TITLE" />
    </div>
  )
}
