export const textTemplatesEnglish = {
    BDS_BREADCRUMBS_BACK_BUTTON_LABEL: 'Back',
  
    // common
    COMPANY_PHONE_NUMBER_LABEL: '303-000-0000',
    ERROR_AGENT:
      'Something has gone wrong. Please check the given fields and try again.',
    SUCCESS_AGENT:
      'The operation was completed successfully.',
    BDS_MESSAGE_INFO: 'Info',
    BDS_MESSAGE_SUCCESS: 'Success',
    BDS_MESSAGE_ERROR: 'Error',
    WELCOME_AGENT: 'Welcome, {name}',
    
    HVAC_UPGRADE_TITLE: 'Enter information below to complete the HVAC upgrade payment.',
    HVAC_UPGRADE_ORIGINAL_DISPATCH_LABEL: 'Original Dispatch',
    HVAC_UPGRADE_LSC_DISPATCH_LABEL: 'LSC Dispatch',
    HVAC_UPGRADE_PARTS_REQUEST_ID_ORIGINAL_LABEL: 'Parts Request ID Original Dispatch',
    HVAC_UPGRADE_PARTS_REQUEST_ID_LSC_LABEL: 'Parts Request ID LSC',
    HVAC_UPGRADE_REPAIR_CREDIT_LABEL: 'Repair Credit',
    HVAC_UPGRADE_PAYMENT_AMOUNT_LABEL: 'Amount charged or financed',
    HVAC_UPGRADE_TOTAL_QUOTE_LABEL: 'Total Quote',
    HVAC_UPGRADE_FINANCED_OPTION_LABEL: 'Financed',
    HVAC_UPGRADE_CC_OPTION_LABEL: 'Credit Card',
    HVAC_UPGRADE_COMMENTS_LABEL: 'Comments',
    HVAC_UPGRADE_SUBMIT_LABEL: 'Submit Request',

    REQUIRED_ERROR: "This field is required",
    NUMERIC_ERROR: "This field must be numeric",
    LENGTH_ERROR: "This field must be 8-9 digits long",
    SYNTAX_ERROR: "This field has invalid syntax",
  }
  