import { useMemo, useRef, useState } from 'react'
import "@ftdr/blueprint-core/themes/ahs-web-app.css";
import { WelcomeAgentHeader } from 'src/components/welcome-agent-header'
import { useAppContext, Button, TextArea } from '@ftdr/blueprint-components-react'
import { useAuth } from '@ftdr/use-auth'
import AddCreditCardComponent, { AddCreditCardImperativeApi } from 'src/components/add-credit-card';
import { proconnectupgradepb as pb } from 'src/extern';
import { ProconnectService } from 'src/services/proconnect-service';
import { CurrencyField, StringField, PaymentTypeRadio } from 'src/components/hvac-input-fields';
import { Notification } from 'src/components/hvac-notification';
import { cleanBackendError, stringToProtoMoney } from 'src/services/proconnect-service/proconnect-service';
import { length, numeric, numericList, required } from 'src/services/validation';
import { InputValidationRef } from 'src/components/hvac-input-fields/input-fields';
import { AxiosError } from 'axios';
import { api } from 'src/utils/api';
import { PaymentOptions, FinancedOption, CreditCardOption } from 'src/utils/shared-types';

export const HVACUpgradePaymentView = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext();
  const { oidcUser } = useAuth();
  const token = oidcUser?.id_token;

  const [dispatchId, setDispatchId] = useState('');
  const [lscDispatchId, setLSCDispatchId] = useState('');
  const [partsRequestId, setPartsRequestId] = useState('');
  const [lscPartsRequestId, setLSCpartsRequestId] = useState('');
  const [repairCredit, setRepairCredit] = useState('');
  const [quoteAmount, setQuote] = useState('');
  const [totalAmount, setAmount] = useState('');
  const [errorText, setErrorText] = useState(localizedText("ERROR_AGENT"));
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [paymentType, setPaymentType] = useState<PaymentOptions>(FinancedOption);
  const [paymentMethodVisible, setPaymentMethodVisible] = useState(false);
  const [comment, setComment] = useState('');
  const [ccKey, setCCKey] = useState(1);
  const [showInputError, setShowInputError] = useState(false);
  const addCreditCardRef = useRef<AddCreditCardImperativeApi>(null);
  const validatorRefs: Array<InputValidationRef | null> = Array(7).fill(null);
  const setRefForIndex = (index: number) => {
    return (ref: InputValidationRef | null) => {validatorRefs[index] = ref}
  }

  const proconnectService = useMemo(() => {
    return new ProconnectService(token || "");
  }, [token]);

  const errorWithText = (text?: string) => {
    setErrorText(text ? text : localizedText("ERROR_AGENT"));
    setShowError(true);
  } 

  const onReturn = () => {
    setSubmitDisabled(false);
  }

  const onSuccess = () => {
    setShowSuccess(true);
    clearInput();
  }

  const onFailure = (error?: AxiosError<string>) => {
    console.dir(error?.response);
    errorWithText(error?.response?.data ? cleanBackendError(error?.response?.data) : undefined);
  }

  const validateInput = () => {
    let valid = true;
    for(let validator of validatorRefs){
      if(!validator?.checkIsValid())
        valid = false;
    }
    if(!valid){
      onFailure();
      onReturn();
    }
    setShowInputError(true);
    return valid;
  }

  const clearInput = () => {
    setDispatchId('');
    setLSCDispatchId('');
    setPartsRequestId('');
    setLSCpartsRequestId('');
    setRepairCredit('');
    setQuote('');
    setAmount('');
    setComment('');
    setCCKey(ccKey+1);
    setShowInputError(false);
  }

  const processPayment = (paymentMethodId?: string) => {
    proconnectService.submitHvacUpgradeRequest(
      lscDispatchId,
      lscPartsRequestId,
      dispatchId,
      partsRequestId,
      paymentMethodId ? pb.PaymentType.CreditCard : pb.PaymentType.Financed,
      stringToProtoMoney(totalAmount),
      stringToProtoMoney(quoteAmount),
      stringToProtoMoney(repairCredit),
      paymentMethodId,
      comment
    ).then(onSuccess).catch(onFailure).finally(onReturn);
  }

  const processCCPayment = () => {
    addCreditCardRef.current?.processPayment(
      {stripe: {owner: {ownerIdentity: { type: 4, dispatchIdentity: {dispatchID: dispatchId}}}, includedInWallet: false}}
    )?.then(
      (result) => {
        if(result?.stripe?.stripeSuccessResponse?.paymentMethod?.paymentMethodID){
          processPayment(result?.stripe?.stripeSuccessResponse?.paymentMethod?.paymentMethodID || undefined)
        }else{
          onFailure();
          onReturn();
        }
      }
    ).catch(onFailure).finally(onReturn);
  }

  return (
    <div>
      <WelcomeAgentHeader />
      <form>
        <div className="flex flex-wrap justify-first max-w-5xl" data-testid="HVAC_Upgrade_Page">
          <StringField validation={[required, numeric, length]} showError={showInputError} ref={setRefForIndex(0)} label={localizedText('HVAC_UPGRADE_ORIGINAL_DISPATCH_LABEL')} value={dispatchId} onChange={setDispatchId} />
          <StringField validation={[required, numeric, length]} showError={showInputError} ref={setRefForIndex(1)} label={localizedText('HVAC_UPGRADE_LSC_DISPATCH_LABEL')} value={lscDispatchId} onChange={setLSCDispatchId} />
          <CurrencyField required ref={setRefForIndex(2)}  showError={showInputError}label={localizedText('HVAC_UPGRADE_TOTAL_QUOTE_LABEL')} value={quoteAmount} onChange={setQuote} />
          <StringField validation={[required, numeric, length]} showError={showInputError} ref={setRefForIndex(3)} label={localizedText('HVAC_UPGRADE_PARTS_REQUEST_ID_ORIGINAL_LABEL')} value={partsRequestId} onChange={setPartsRequestId} />
          <StringField validation={[required, numericList]} showError={showInputError} ref={setRefForIndex(4)} label={localizedText('HVAC_UPGRADE_PARTS_REQUEST_ID_LSC_LABEL')} value={lscPartsRequestId} onChange={setLSCpartsRequestId} />
          <CurrencyField required ref={setRefForIndex(5)} showError={showInputError} label={localizedText('HVAC_UPGRADE_REPAIR_CREDIT_LABEL')} value={repairCredit} onChange={setRepairCredit} />
        </div>
        <PaymentTypeRadio 
          value={paymentType} 
          ccCallback={() => {
            addCreditCardRef?.current?.setOpenPaymentMethod("CREDIT_CARD");
            setPaymentMethodVisible(true);
          }}
          finCallback={() => {
            addCreditCardRef?.current?.setOpenPaymentMethod(undefined);
            setPaymentMethodVisible(false);
          }}
          stateCallback={setPaymentType}
        />
        <CurrencyField required ref={setRefForIndex(6)} showError={showInputError} label={localizedText('HVAC_UPGRADE_PAYMENT_AMOUNT_LABEL')} value={totalAmount} onChange={setAmount} />
        <AddCreditCardComponent 
          token={token || ""} 
          baseURL={api.config.BASE_URL} 
          paymentMethodVisible={paymentMethodVisible}
          ref={addCreditCardRef}
          key={ccKey}
        />
        <div className="mt-6 mr-5">
          <TextArea 
            formField 
            className="mb-5" 
            rows={6} 
            value={comment} 
            label={localizedText('HVAC_UPGRADE_COMMENTS_LABEL')} 
            onChange={value => setComment(value.target.value)} 
          />
        </div>
        <Button
          className="mt-4"
          type="submit"
          variant="filled"
          color="primary"
          label={localizedText('HVAC_UPGRADE_SUBMIT_LABEL')}
          data-testid="submit_button"
          size="medium"
          width="full"
          disabled={submitDisabled}
          onClick={(e) => {
            e.preventDefault();
            setSubmitDisabled(true);
            if(validateInput()) {
              if(paymentType === CreditCardOption){
                processCCPayment();
              }else{
                processPayment();
              }
            }
          }}
        />
      </form>
    { showSuccess ? <Notification type="success"  text={localizedText("SUCCESS_AGENT")} onClose={() => setShowSuccess(false)} /> : "" }
    { showError ? <Notification type="error"  text={errorText} onClose={() => setShowError(false)} /> : "" }
    </div>
  )
}