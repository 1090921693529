import { useEffect } from 'react'
import { ProgressIndicator } from '@ftdr/blueprint-components-react'
import { useAuth } from '@ftdr/use-auth'

export const Callback = () => {
  const { callback } = useAuth()

  useEffect(() => {
    callback()
  }, [callback])

  return (
    <div className="h-full w-full flex justify-center items-center">
      <ProgressIndicator size="medium" />
    </div>
  )
}
