import { api } from 'src/utils/api'
import { IdentityProviders } from 'src/utils/shared-types'
const AZURE_AHS_TENANT_ID = '050e16e5-2cf7-4ca6-8d10-9a639dbeab71'

export const getIdentityProvidersConfig = () => {
  const azureAgentsConfig = {
    debug: false, // displays console logs
    nickname: IdentityProviders.Azure,
    authority: `https://login.microsoftonline.com/${AZURE_AHS_TENANT_ID}/oauth2/v2.0`,
    client_id: api.config.AZURE_CLIENT_ID,
    metadata_uri: `https://login.microsoftonline.com/${AZURE_AHS_TENANT_ID}/v2.0/.well-known/openid-configuration`,
    post_logout_redirect_uri: window.location.origin, // where users go after logout
    redirect_uri: window.location.origin + '/callback', // callback url
    scope: 'openid email profile',
  }
  return [azureAgentsConfig]
}
