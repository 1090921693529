import { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { HVACUpgradePaymentView } from 'src/screens/hvac-upgrade-payment'
import { Callback } from 'src/screens/callback'
import { useAuth } from '@ftdr/use-auth'
import { api } from 'src/utils/api'
import { IdentityProviders } from 'src/utils/shared-types'

const AuthenticatedAgentRoute = (props: any) => {
  const { oidcUser, login } = useAuth()

  useEffect(() => {
    if (!oidcUser?.expires_in) return
    const interval = setTimeout(() => {
      login(IdentityProviders.Azure)
    }, oidcUser.expires_in * 1000)
    return () => {
      if (interval) clearTimeout(interval)
    }
  }, [oidcUser, login])

  if (!oidcUser || oidcUser?.expired) {
    login(IdentityProviders.Azure)
    return null
  }
  api.idToken = oidcUser.id_token
  return <Route {...props} />
}

export const ProconnectUpgradePortalRoutes = () => {
  const history = useHistory()

  useEffect(() => {
    // Taelium view trigger
    const unregisterListener = history.listen(() => {
      window.utag?.view({
        virtual_page_path: window.location.pathname,
        virtual_page_url: window.location.href,
      })
    })
    return unregisterListener
  }, [history])

  return (
    <Switch>
      <Route path="/callback" exact component={Callback} />
      <AuthenticatedAgentRoute path="/" exact component={HVACUpgradePaymentView} />
    </Switch>
  )
}