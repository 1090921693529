import { App, SupportedLanguages } from '@ftdr/blueprint-components-react'
import { textTemplatesEnglish } from 'src/i18n/text-templates-english'
import { ProconnectUpgradePortalRoutes } from 'src/components/proconnect-upgrade-portal-routes'
import { NavBar } from 'src/components/nav-bar'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '@ftdr/use-auth'
import { getIdentityProvidersConfig } from './identity-providers-config'

const lang = navigator.language?.replace('-', '_') as SupportedLanguages
const textTemplatesByLanguage = {
  en_US: textTemplatesEnglish,
}
const appSettings = {
  language: textTemplatesByLanguage[lang] ? lang : 'en_US',
  textTemplatesByLanguage,
}

export const ProconnectUpgradePortalApp = () => {
//   const splitSdkConfig: SplitIO.IBrowserSettings = {
//     core: {
//       authorizationKey: clientFlagKeys[api.config.ENV],
//       key: 'key',
//     },
//   }
//   useEffect(() => {
//     //Initialize Tealium
//     window.utag_cfg_ovrd = { noview: true }
//     const url = `https://tags.tiqcdn.com/utag/americanhomeshield/ahs-appliances.ahs.com/${api.config.TAELIUM_ENV}/utag.js`
//     const script = document.createElement('script')
//     Object.assign(script, {
//       src: url,
//       async: true,
//     })
//     const container = document.getElementsByTagName('script')[0]
//     container?.parentNode?.insertBefore(script, container)
//   }, [])

  return (
    <App appSettings={appSettings}>
      <AuthProvider serviceConfigs={getIdentityProvidersConfig()}>
        <BrowserRouter>
          <NavBar />
          <div className="py-10 px-4 lg:px-48">
            <ProconnectUpgradePortalRoutes />
          </div>
        </BrowserRouter>
      </AuthProvider>
    </App>
  )
}
