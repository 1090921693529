import { common, proconnectupgradepb as pb } from '../../extern';
import { ProtobufHttpClient, TokenHandlingHttpClient } from '@ftdr/http-utils';
import { api } from 'src/utils/api';

export const stringToProtoMoney = (value: string): common.IMoney => {
  const [unitString, nanoString, rest] = value.split('.');
  if(rest) throw SyntaxError(`The provided money string "${value}" has improper syntax.`);
  return {
    currencyCode: "USD",
    units: parseInt(unitString || "0"),
    nanos: parseInt((nanoString || "").padEnd(9, "0"))
  } as common.IMoney
}

export const VALIDATION_ERROR_PREFIX = "REQUEST_VALIDATION_ERROR: could not validate the request: "
export const cleanBackendError = (response: string): string => {
  return response.length > 0 ? response.replaceAll(VALIDATION_ERROR_PREFIX, "").replaceAll(".", "") : "";
}

export class ProconnectService {
  constructor(token: string){
    this.client = new ProtobufHttpClient(
      new TokenHandlingHttpClient(token)
    );
  }

  private client: ProtobufHttpClient

  public submitHvacUpgradeRequest = (
        lscDispatchID: string, 
        lscPartsRequestID: string, 
        originalDispatchID: string, 
        originalPartsRequestID: string, 
        paymentType: pb.PaymentType,
        paymentAmount: common.IMoney, 
        TotalQuote: common.IMoney, 
        repairCredit: common.IMoney,
        paymentMethodID?: string,
        comments?: string
    ) => {
      const requestData = pb.SubmitHVACUpgradePaymentRequest.encode({
        payment: {
          TotalQuote,
          lscDispatchID,
          lscPartsRequestID,
          originalDispatchID,
          originalPartsRequestID,
          paymentAmount,
          repairCredit,
          paymentType,
          paymentMethodID,
          comments
        }
      }).finish();
    return this.client.post<ArrayBuffer>(
      `${api.config.BASE_URL}/${api.config.PROCONNECT_PATH}`,
      requestData.buffer.slice(requestData.byteOffset, requestData.byteOffset + requestData.byteLength),
      { responseType: 'text' }
    )
  }
}