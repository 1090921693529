import { useAppContext, Input, RadioGroup, RadioGroupItem } from '@ftdr/blueprint-components-react';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { validate, ValidationCodes, Validator } from 'src/services/validation';
import { CreditCardOption, FinancedOption, PaymentOptions } from 'src/utils/shared-types';

const INPUT_WRAPPER_CLASSES="mb-6 mr-5";
const INPUT_CLASSES="w-56";

export interface StringFieldProps {
  label: string,
  value: string,
  showError: boolean,
  onChange: React.Dispatch<React.SetStateAction<string>>
  validation?: Validator[];
}

export interface CurrencyFieldProps {
  label: string,
  value: string,
  showError: boolean,
  onChange: React.Dispatch<React.SetStateAction<string>>
  required?: boolean
}

export interface PaymentTypeRadioProps {
  value: string,
  ccCallback?: () => void,
  finCallback?: () => void,
  stateCallback: React.Dispatch<React.SetStateAction<PaymentOptions>>
}

export interface InputValidationRef {
  checkIsValid: () => boolean;
}

export const StringField = forwardRef<InputValidationRef, StringFieldProps>(({label, value, onChange, validation, showError}: StringFieldProps, ref) => {
  const { appSettings: { localizedText } } = useAppContext();

  const status = useMemo(
    () => validation ? validate(value, validation) : ValidationCodes.VALID, 
  [validation, value]);

  useImperativeHandle(
    ref,
    () => {
      return {
        checkIsValid: () => {return !!validation && validate(value, validation) === ValidationCodes.VALID},
      }
    },
    [validation, value],
  );

  return (
    <div className={INPUT_WRAPPER_CLASSES}>
      <Input
        required
        error={ showError && status !== ValidationCodes.VALID ? localizedText(status) : undefined }
        formField
        label={label}
        data-testid={`String_Field_${label}`}
        className={INPUT_CLASSES}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
});

export const CurrencyField = forwardRef<InputValidationRef, CurrencyFieldProps>(({label, onChange, value, required, showError}: CurrencyFieldProps, ref) => {
  const { appSettings: { localizedText } } = useAppContext();

  const status = useMemo(
    () => required && !value ? ValidationCodes.REQUIRED_ERROR : ValidationCodes.VALID, 
  [required, value]);

  useImperativeHandle(
    ref,
    () => {
      return {
        checkIsValid: () => {return !!required && value !== ''}
      }
    },
    [value, required],
  );

  return (
  <div className={INPUT_WRAPPER_CLASSES}>
    <Input
      required
      error={ showError && status !== ValidationCodes.VALID ? localizedText(status) : undefined }
      formField
      label={label}
      className={INPUT_CLASSES}
      inputElementRenderer={(props) => 
          <CurrencyInput
            placeholder="$0.00"
            allowNegativeValue={false}
            data-testid={`Currency_Field_${label}`}
            intlConfig={{ locale: 'en-US', currency: "USD" }}
            decimalsLimit={2}
            className={props.className}
            ref={props.ref}
            value={value}
            onValueChange={(value) => onChange(value || "")}
          />
        }
      />
    </div>
  )
});

export const PaymentTypeRadio = ({value, ccCallback, finCallback, stateCallback}: PaymentTypeRadioProps) => {
  const {
    appSettings: { localizedText },
  } = useAppContext();

  const PAYMENT_OPTIONS: RadioGroupItem[] = [
    { value: FinancedOption, label: localizedText("HVAC_UPGRADE_FINANCED_OPTION_LABEL") },
    { value: CreditCardOption, label: localizedText("HVAC_UPGRADE_CC_OPTION_LABEL") },
  ];

  return (
    <RadioGroup
      formField
      className="mb-5"
      data-testid={`Payment_Type_Radio_Group`}
      value={value}
      onChange={(value) => {
        if(value === CreditCardOption){
          ccCallback?.();
        }else{
          finCallback?.();
        }
        if(value === FinancedOption || value === CreditCardOption)
          stateCallback(value);
      }}
      radios={PAYMENT_OPTIONS}
      orientation="horizontal"
      label="Payment Type"
    />
  )
}