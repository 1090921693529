import ReactDOM from 'react-dom'
import { ProconnectUpgradePortalApp } from './components/proconnect-upgrade-portal-app'
import { api } from './utils/api'

api.loadConfig().finally(() => {
  ReactDOM.render(
    <ProconnectUpgradePortalApp />,
    document.getElementById('root') as HTMLElement
  )
})
