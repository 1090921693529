export type Validator = (value: string) => ValidationCodes;
export enum ValidationCodes {
  VALID = 'VALID',
  REQUIRED_ERROR = 'REQUIRED_ERROR',
  NUMERIC_ERROR = 'NUMERIC_ERROR',
  LENGTH_ERROR = 'LENGTH_ERROR',
  SYNTAX_ERROR = 'SYNTAX_ERROR'
}

export const required: Validator = (value: string) => {
  if (!value.toString().trim().length) {
    return ValidationCodes.REQUIRED_ERROR;
  }
  return ValidationCodes.VALID;
};

const lengthR = new RegExp('^.{8,9}$');
export const length: Validator = (value: any) => {
  if(!lengthR.test(value)){
    return ValidationCodes.LENGTH_ERROR;
  }
  return ValidationCodes.VALID;
}

const numericR = new RegExp('^[0-9]*$');
export const numeric: Validator = (value: string) => {
  if(!numericR.test(value)){
    return ValidationCodes.NUMERIC_ERROR;
  }
  return ValidationCodes.VALID;
}

const numericListR = new RegExp('^[0-9]*(,[0-9]*)*$');
export const numericList: Validator = (value: any) => {
  if(!numericListR.test(value)){
    return ValidationCodes.SYNTAX_ERROR;
  }
  return ValidationCodes.VALID;
}

export const validate = (value: string, validators: Validator[]) => {
    for(let validator of validators) {
        let result = validator(value);
        if(result !== ValidationCodes.VALID){
            return result;
        }
    }
    return ValidationCodes.VALID;
}