import { PaymentMethodMicroFrontend, PaymentMethods, PaymentsMethodMfeExposedApi, ProcessPayment, ProcessPaymentRequestData } from '@ftdr/payment-method-micro-frontend';
import { createRef, forwardRef, RefObject, useImperativeHandle } from 'react';
import { api } from 'src/utils/api';

export interface AddCreditCardComponentProps {
  baseURL: string;
  token: string;
  paymentMethodVisible: boolean;
}

export interface AddCreditCardImperativeApi {
  processPayment: ProcessPayment<ProcessPaymentRequestData>;
  setOpenPaymentMethod: (
    activePaymentMethod?: PaymentMethods
  ) => void | undefined;
}

const AVAILABLE_PAYMENT_METHODS = {
  CREDIT_CARD: true,
  BANK_ACCOUNT: false
}

export const AddCreditCardComponent = forwardRef<AddCreditCardImperativeApi, AddCreditCardComponentProps>
  ((props, ref) => {
    const paymentMethodMfeRef: RefObject<PaymentsMethodMfeExposedApi> = createRef();

    useImperativeHandle(ref, () => (
      {
        processPayment: (requestData) =>
          paymentMethodMfeRef?.current?.processPayment(requestData),
        setOpenPaymentMethod: (activePaymentMethod?: PaymentMethods) => {
          paymentMethodMfeRef?.current?.setOpenPaymentMethod(activePaymentMethod);
          // if (activePaymentMethod != undefined) {
          //   props.paymentMethodVisible = true;
          // }
        }
      }
    ), [paymentMethodMfeRef]);
    
    return (
      <div data-testid="payment_method_mfe">
        {props.paymentMethodVisible ? (
          <PaymentMethodMicroFrontend
            applicationName={api.config.APP_NAME}
            token={props.token}
            baseURL={props.baseURL}
            ref={paymentMethodMfeRef}
            cardPaymentProcessor="stripe"
            availablePaymentMethods={AVAILABLE_PAYMENT_METHODS}
          />
        ) : (
          null
        )}
        
      </div>
    )
  }
);