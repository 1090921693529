/* eslint-disable */
/*eslint-disable*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots.proconnectupgrade || ($protobuf.roots.proconnectupgrade = {});

export const proconnectupgradepb = $root.proconnectupgradepb = (() => {

    /**
     * Namespace proconnectupgradepb.
     * @exports proconnectupgradepb
     * @namespace
     */
    const proconnectupgradepb = {};

    proconnectupgradepb.HVACUpgradePayment = (function() {

        /**
         * Properties of a HVACUpgradePayment.
         * @memberof proconnectupgradepb
         * @interface IHVACUpgradePayment
         * @property {string|null} [originalDispatchID] HVACUpgradePayment originalDispatchID
         * @property {string|null} [lscDispatchID] HVACUpgradePayment lscDispatchID
         * @property {common.IMoney|null} [TotalQuote] HVACUpgradePayment TotalQuote
         * @property {string|null} [originalPartsRequestID] HVACUpgradePayment originalPartsRequestID
         * @property {string|null} [lscPartsRequestID] HVACUpgradePayment lscPartsRequestID
         * @property {common.IMoney|null} [repairCredit] HVACUpgradePayment repairCredit
         * @property {proconnectupgradepb.PaymentType|null} [paymentType] HVACUpgradePayment paymentType
         * @property {common.IMoney|null} [paymentAmount] HVACUpgradePayment paymentAmount
         * @property {string|null} [paymentMethodID] HVACUpgradePayment paymentMethodID
         * @property {string|null} [comments] HVACUpgradePayment comments
         */

        /**
         * Constructs a new HVACUpgradePayment.
         * @memberof proconnectupgradepb
         * @classdesc Represents a HVACUpgradePayment.
         * @implements IHVACUpgradePayment
         * @constructor
         * @param {proconnectupgradepb.IHVACUpgradePayment=} [p] Properties to set
         */
        function HVACUpgradePayment(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HVACUpgradePayment originalDispatchID.
         * @member {string} originalDispatchID
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.originalDispatchID = "";

        /**
         * HVACUpgradePayment lscDispatchID.
         * @member {string} lscDispatchID
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.lscDispatchID = "";

        /**
         * HVACUpgradePayment TotalQuote.
         * @member {common.IMoney|null|undefined} TotalQuote
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.TotalQuote = null;

        /**
         * HVACUpgradePayment originalPartsRequestID.
         * @member {string} originalPartsRequestID
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.originalPartsRequestID = "";

        /**
         * HVACUpgradePayment lscPartsRequestID.
         * @member {string} lscPartsRequestID
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.lscPartsRequestID = "";

        /**
         * HVACUpgradePayment repairCredit.
         * @member {common.IMoney|null|undefined} repairCredit
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.repairCredit = null;

        /**
         * HVACUpgradePayment paymentType.
         * @member {proconnectupgradepb.PaymentType} paymentType
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.paymentType = 0;

        /**
         * HVACUpgradePayment paymentAmount.
         * @member {common.IMoney|null|undefined} paymentAmount
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.paymentAmount = null;

        /**
         * HVACUpgradePayment paymentMethodID.
         * @member {string} paymentMethodID
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.paymentMethodID = "";

        /**
         * HVACUpgradePayment comments.
         * @member {string} comments
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @instance
         */
        HVACUpgradePayment.prototype.comments = "";

        /**
         * Creates a new HVACUpgradePayment instance using the specified properties.
         * @function create
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @static
         * @param {proconnectupgradepb.IHVACUpgradePayment=} [properties] Properties to set
         * @returns {proconnectupgradepb.HVACUpgradePayment} HVACUpgradePayment instance
         */
        HVACUpgradePayment.create = function create(properties) {
            return new HVACUpgradePayment(properties);
        };

        /**
         * Encodes the specified HVACUpgradePayment message. Does not implicitly {@link proconnectupgradepb.HVACUpgradePayment.verify|verify} messages.
         * @function encode
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @static
         * @param {proconnectupgradepb.IHVACUpgradePayment} m HVACUpgradePayment message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HVACUpgradePayment.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.originalDispatchID != null && Object.hasOwnProperty.call(m, "originalDispatchID"))
                w.uint32(10).string(m.originalDispatchID);
            if (m.lscDispatchID != null && Object.hasOwnProperty.call(m, "lscDispatchID"))
                w.uint32(18).string(m.lscDispatchID);
            if (m.TotalQuote != null && Object.hasOwnProperty.call(m, "TotalQuote"))
                $root.common.Money.encode(m.TotalQuote, w.uint32(26).fork()).ldelim();
            if (m.originalPartsRequestID != null && Object.hasOwnProperty.call(m, "originalPartsRequestID"))
                w.uint32(34).string(m.originalPartsRequestID);
            if (m.lscPartsRequestID != null && Object.hasOwnProperty.call(m, "lscPartsRequestID"))
                w.uint32(42).string(m.lscPartsRequestID);
            if (m.repairCredit != null && Object.hasOwnProperty.call(m, "repairCredit"))
                $root.common.Money.encode(m.repairCredit, w.uint32(50).fork()).ldelim();
            if (m.paymentType != null && Object.hasOwnProperty.call(m, "paymentType"))
                w.uint32(56).int32(m.paymentType);
            if (m.paymentAmount != null && Object.hasOwnProperty.call(m, "paymentAmount"))
                $root.common.Money.encode(m.paymentAmount, w.uint32(66).fork()).ldelim();
            if (m.paymentMethodID != null && Object.hasOwnProperty.call(m, "paymentMethodID"))
                w.uint32(74).string(m.paymentMethodID);
            if (m.comments != null && Object.hasOwnProperty.call(m, "comments"))
                w.uint32(82).string(m.comments);
            return w;
        };

        /**
         * Decodes a HVACUpgradePayment message from the specified reader or buffer.
         * @function decode
         * @memberof proconnectupgradepb.HVACUpgradePayment
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {proconnectupgradepb.HVACUpgradePayment} HVACUpgradePayment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HVACUpgradePayment.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.proconnectupgradepb.HVACUpgradePayment();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.originalDispatchID = r.string();
                    break;
                case 2:
                    m.lscDispatchID = r.string();
                    break;
                case 3:
                    m.TotalQuote = $root.common.Money.decode(r, r.uint32());
                    break;
                case 4:
                    m.originalPartsRequestID = r.string();
                    break;
                case 5:
                    m.lscPartsRequestID = r.string();
                    break;
                case 6:
                    m.repairCredit = $root.common.Money.decode(r, r.uint32());
                    break;
                case 7:
                    m.paymentType = r.int32();
                    break;
                case 8:
                    m.paymentAmount = $root.common.Money.decode(r, r.uint32());
                    break;
                case 9:
                    m.paymentMethodID = r.string();
                    break;
                case 10:
                    m.comments = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return HVACUpgradePayment;
    })();

    /**
     * PaymentType enum.
     * @name proconnectupgradepb.PaymentType
     * @enum {number}
     * @property {number} Undefined_PaymentType=0 Undefined_PaymentType value
     * @property {number} Financed=1 Financed value
     * @property {number} CreditCard=2 CreditCard value
     */
    proconnectupgradepb.PaymentType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "Undefined_PaymentType"] = 0;
        values[valuesById[1] = "Financed"] = 1;
        values[valuesById[2] = "CreditCard"] = 2;
        return values;
    })();

    proconnectupgradepb.SnowflakeHVACUpgradePayment = (function() {

        /**
         * Properties of a SnowflakeHVACUpgradePayment.
         * @memberof proconnectupgradepb
         * @interface ISnowflakeHVACUpgradePayment
         * @property {string|null} [paymentID] SnowflakeHVACUpgradePayment paymentID
         * @property {proconnectupgradepb.IHVACUpgradePayment|null} [payment] SnowflakeHVACUpgradePayment payment
         * @property {google.protobuf.ITimestamp|null} [createdAt] SnowflakeHVACUpgradePayment createdAt
         */

        /**
         * Constructs a new SnowflakeHVACUpgradePayment.
         * @memberof proconnectupgradepb
         * @classdesc Represents a SnowflakeHVACUpgradePayment.
         * @implements ISnowflakeHVACUpgradePayment
         * @constructor
         * @param {proconnectupgradepb.ISnowflakeHVACUpgradePayment=} [p] Properties to set
         */
        function SnowflakeHVACUpgradePayment(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SnowflakeHVACUpgradePayment paymentID.
         * @member {string} paymentID
         * @memberof proconnectupgradepb.SnowflakeHVACUpgradePayment
         * @instance
         */
        SnowflakeHVACUpgradePayment.prototype.paymentID = "";

        /**
         * SnowflakeHVACUpgradePayment payment.
         * @member {proconnectupgradepb.IHVACUpgradePayment|null|undefined} payment
         * @memberof proconnectupgradepb.SnowflakeHVACUpgradePayment
         * @instance
         */
        SnowflakeHVACUpgradePayment.prototype.payment = null;

        /**
         * SnowflakeHVACUpgradePayment createdAt.
         * @member {google.protobuf.ITimestamp|null|undefined} createdAt
         * @memberof proconnectupgradepb.SnowflakeHVACUpgradePayment
         * @instance
         */
        SnowflakeHVACUpgradePayment.prototype.createdAt = null;

        /**
         * Creates a new SnowflakeHVACUpgradePayment instance using the specified properties.
         * @function create
         * @memberof proconnectupgradepb.SnowflakeHVACUpgradePayment
         * @static
         * @param {proconnectupgradepb.ISnowflakeHVACUpgradePayment=} [properties] Properties to set
         * @returns {proconnectupgradepb.SnowflakeHVACUpgradePayment} SnowflakeHVACUpgradePayment instance
         */
        SnowflakeHVACUpgradePayment.create = function create(properties) {
            return new SnowflakeHVACUpgradePayment(properties);
        };

        /**
         * Encodes the specified SnowflakeHVACUpgradePayment message. Does not implicitly {@link proconnectupgradepb.SnowflakeHVACUpgradePayment.verify|verify} messages.
         * @function encode
         * @memberof proconnectupgradepb.SnowflakeHVACUpgradePayment
         * @static
         * @param {proconnectupgradepb.ISnowflakeHVACUpgradePayment} m SnowflakeHVACUpgradePayment message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SnowflakeHVACUpgradePayment.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.paymentID != null && Object.hasOwnProperty.call(m, "paymentID"))
                w.uint32(10).string(m.paymentID);
            if (m.payment != null && Object.hasOwnProperty.call(m, "payment"))
                $root.proconnectupgradepb.HVACUpgradePayment.encode(m.payment, w.uint32(18).fork()).ldelim();
            if (m.createdAt != null && Object.hasOwnProperty.call(m, "createdAt"))
                $root.google.protobuf.Timestamp.encode(m.createdAt, w.uint32(26).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a SnowflakeHVACUpgradePayment message from the specified reader or buffer.
         * @function decode
         * @memberof proconnectupgradepb.SnowflakeHVACUpgradePayment
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {proconnectupgradepb.SnowflakeHVACUpgradePayment} SnowflakeHVACUpgradePayment
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SnowflakeHVACUpgradePayment.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.proconnectupgradepb.SnowflakeHVACUpgradePayment();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.paymentID = r.string();
                    break;
                case 2:
                    m.payment = $root.proconnectupgradepb.HVACUpgradePayment.decode(r, r.uint32());
                    break;
                case 3:
                    m.createdAt = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return SnowflakeHVACUpgradePayment;
    })();

    proconnectupgradepb.SubmitHVACUpgradePaymentRequest = (function() {

        /**
         * Properties of a SubmitHVACUpgradePaymentRequest.
         * @memberof proconnectupgradepb
         * @interface ISubmitHVACUpgradePaymentRequest
         * @property {proconnectupgradepb.IHVACUpgradePayment|null} [payment] SubmitHVACUpgradePaymentRequest payment
         */

        /**
         * Constructs a new SubmitHVACUpgradePaymentRequest.
         * @memberof proconnectupgradepb
         * @classdesc Represents a SubmitHVACUpgradePaymentRequest.
         * @implements ISubmitHVACUpgradePaymentRequest
         * @constructor
         * @param {proconnectupgradepb.ISubmitHVACUpgradePaymentRequest=} [p] Properties to set
         */
        function SubmitHVACUpgradePaymentRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * SubmitHVACUpgradePaymentRequest payment.
         * @member {proconnectupgradepb.IHVACUpgradePayment|null|undefined} payment
         * @memberof proconnectupgradepb.SubmitHVACUpgradePaymentRequest
         * @instance
         */
        SubmitHVACUpgradePaymentRequest.prototype.payment = null;

        /**
         * Creates a new SubmitHVACUpgradePaymentRequest instance using the specified properties.
         * @function create
         * @memberof proconnectupgradepb.SubmitHVACUpgradePaymentRequest
         * @static
         * @param {proconnectupgradepb.ISubmitHVACUpgradePaymentRequest=} [properties] Properties to set
         * @returns {proconnectupgradepb.SubmitHVACUpgradePaymentRequest} SubmitHVACUpgradePaymentRequest instance
         */
        SubmitHVACUpgradePaymentRequest.create = function create(properties) {
            return new SubmitHVACUpgradePaymentRequest(properties);
        };

        /**
         * Encodes the specified SubmitHVACUpgradePaymentRequest message. Does not implicitly {@link proconnectupgradepb.SubmitHVACUpgradePaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof proconnectupgradepb.SubmitHVACUpgradePaymentRequest
         * @static
         * @param {proconnectupgradepb.ISubmitHVACUpgradePaymentRequest} m SubmitHVACUpgradePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SubmitHVACUpgradePaymentRequest.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.payment != null && Object.hasOwnProperty.call(m, "payment"))
                $root.proconnectupgradepb.HVACUpgradePayment.encode(m.payment, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a SubmitHVACUpgradePaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof proconnectupgradepb.SubmitHVACUpgradePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {proconnectupgradepb.SubmitHVACUpgradePaymentRequest} SubmitHVACUpgradePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SubmitHVACUpgradePaymentRequest.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.proconnectupgradepb.SubmitHVACUpgradePaymentRequest();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.payment = $root.proconnectupgradepb.HVACUpgradePayment.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return SubmitHVACUpgradePaymentRequest;
    })();

    return proconnectupgradepb;
})();

export const common = $root.common = (() => {

    /**
     * Namespace common.
     * @exports common
     * @namespace
     */
    const common = {};

    /**
     * Tenant enum.
     * @name common.Tenant
     * @enum {number}
     * @property {number} TENANT_UNDEFINED=0 TENANT_UNDEFINED value
     * @property {number} TEST=1 TEST value
     * @property {number} AHS=2 AHS value
     * @property {number} CD=3 CD value
     * @property {number} HSA=4 HSA value
     * @property {number} OG=5 OG value
     * @property {number} LM=6 LM value
     * @property {number} FRONTDOOR=7 FRONTDOOR value
     * @property {number} AHSPC=8 AHSPC value
     * @property {number} HSAPC=9 HSAPC value
     */
    common.Tenant = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TENANT_UNDEFINED"] = 0;
        values[valuesById[1] = "TEST"] = 1;
        values[valuesById[2] = "AHS"] = 2;
        values[valuesById[3] = "CD"] = 3;
        values[valuesById[4] = "HSA"] = 4;
        values[valuesById[5] = "OG"] = 5;
        values[valuesById[6] = "LM"] = 6;
        values[valuesById[7] = "FRONTDOOR"] = 7;
        values[valuesById[8] = "AHSPC"] = 8;
        values[valuesById[9] = "HSAPC"] = 9;
        return values;
    })();

    /**
     * Alert enum.
     * @name common.Alert
     * @enum {number}
     * @property {number} ALERTS_UNDEFINED=0 ALERTS_UNDEFINED value
     * @property {number} ALERTS_UNSCHEDULED=1 ALERTS_UNSCHEDULED value
     * @property {number} ALERTS_NEED_STATUS_UPDATE=2 ALERTS_NEED_STATUS_UPDATE value
     * @property {number} ALERTS_OPEN_LONGER_THAN_AVERAGE=3 ALERTS_OPEN_LONGER_THAN_AVERAGE value
     * @property {number} ALERTS_SCHEDULE_NEXT_APPOINTMENT=4 ALERTS_SCHEDULE_NEXT_APPOINTMENT value
     * @property {number} ALERTS_RESUBMIT_REQUEST=5 ALERTS_RESUBMIT_REQUEST value
     */
    common.Alert = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ALERTS_UNDEFINED"] = 0;
        values[valuesById[1] = "ALERTS_UNSCHEDULED"] = 1;
        values[valuesById[2] = "ALERTS_NEED_STATUS_UPDATE"] = 2;
        values[valuesById[3] = "ALERTS_OPEN_LONGER_THAN_AVERAGE"] = 3;
        values[valuesById[4] = "ALERTS_SCHEDULE_NEXT_APPOINTMENT"] = 4;
        values[valuesById[5] = "ALERTS_RESUBMIT_REQUEST"] = 5;
        return values;
    })();

    /**
     * Status enum.
     * @name common.Status
     * @enum {number}
     * @property {number} STATUS_UNDEFINED=0 STATUS_UNDEFINED value
     * @property {number} SUCCESS=1 SUCCESS value
     * @property {number} FAIL=2 FAIL value
     */
    common.Status = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "STATUS_UNDEFINED"] = 0;
        values[valuesById[1] = "SUCCESS"] = 1;
        values[valuesById[2] = "FAIL"] = 2;
        return values;
    })();

    /**
     * UserRole enum.
     * @name common.UserRole
     * @enum {number}
     * @property {number} USER_ROLE_UNDEFINED=0 USER_ROLE_UNDEFINED value
     * @property {number} CONTRACTOR=1 CONTRACTOR value
     * @property {number} AGENT=2 AGENT value
     * @property {number} ADMIN=3 ADMIN value
     * @property {number} TECHNICIAN=4 TECHNICIAN value
     * @property {number} PARTNER=5 PARTNER value
     * @property {number} CUSTOMER=6 CUSTOMER value
     * @property {number} DEVELOPER=7 DEVELOPER value
     */
    common.UserRole = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "USER_ROLE_UNDEFINED"] = 0;
        values[valuesById[1] = "CONTRACTOR"] = 1;
        values[valuesById[2] = "AGENT"] = 2;
        values[valuesById[3] = "ADMIN"] = 3;
        values[valuesById[4] = "TECHNICIAN"] = 4;
        values[valuesById[5] = "PARTNER"] = 5;
        values[valuesById[6] = "CUSTOMER"] = 6;
        values[valuesById[7] = "DEVELOPER"] = 7;
        return values;
    })();

    common.HealthCheckDetail = (function() {

        /**
         * Properties of a HealthCheckDetail.
         * @memberof common
         * @interface IHealthCheckDetail
         * @property {string|null} [componentID] HealthCheckDetail componentID
         * @property {string|null} [type] HealthCheckDetail type
         * @property {number|null} [observedValue] HealthCheckDetail observedValue
         * @property {string|null} [observedUnit] HealthCheckDetail observedUnit
         * @property {string|null} [status] HealthCheckDetail status
         * @property {google.protobuf.ITimestamp|null} [time] HealthCheckDetail time
         * @property {string|null} [output] HealthCheckDetail output
         */

        /**
         * Constructs a new HealthCheckDetail.
         * @memberof common
         * @classdesc Represents a HealthCheckDetail.
         * @implements IHealthCheckDetail
         * @constructor
         * @param {common.IHealthCheckDetail=} [p] Properties to set
         */
        function HealthCheckDetail(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HealthCheckDetail componentID.
         * @member {string} componentID
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.componentID = "";

        /**
         * HealthCheckDetail type.
         * @member {string} type
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.type = "";

        /**
         * HealthCheckDetail observedValue.
         * @member {number} observedValue
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.observedValue = 0;

        /**
         * HealthCheckDetail observedUnit.
         * @member {string} observedUnit
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.observedUnit = "";

        /**
         * HealthCheckDetail status.
         * @member {string} status
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.status = "";

        /**
         * HealthCheckDetail time.
         * @member {google.protobuf.ITimestamp|null|undefined} time
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.time = null;

        /**
         * HealthCheckDetail output.
         * @member {string} output
         * @memberof common.HealthCheckDetail
         * @instance
         */
        HealthCheckDetail.prototype.output = "";

        /**
         * Creates a new HealthCheckDetail instance using the specified properties.
         * @function create
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.IHealthCheckDetail=} [properties] Properties to set
         * @returns {common.HealthCheckDetail} HealthCheckDetail instance
         */
        HealthCheckDetail.create = function create(properties) {
            return new HealthCheckDetail(properties);
        };

        /**
         * Encodes the specified HealthCheckDetail message. Does not implicitly {@link common.HealthCheckDetail.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheckDetail
         * @static
         * @param {common.IHealthCheckDetail} m HealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckDetail.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.componentID != null && Object.hasOwnProperty.call(m, "componentID"))
                w.uint32(10).string(m.componentID);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(18).string(m.type);
            if (m.observedValue != null && Object.hasOwnProperty.call(m, "observedValue"))
                w.uint32(25).double(m.observedValue);
            if (m.observedUnit != null && Object.hasOwnProperty.call(m, "observedUnit"))
                w.uint32(34).string(m.observedUnit);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(42).string(m.status);
            if (m.time != null && Object.hasOwnProperty.call(m, "time"))
                $root.google.protobuf.Timestamp.encode(m.time, w.uint32(50).fork()).ldelim();
            if (m.output != null && Object.hasOwnProperty.call(m, "output"))
                w.uint32(58).string(m.output);
            return w;
        };

        /**
         * Decodes a HealthCheckDetail message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.HealthCheckDetail} HealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckDetail.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.HealthCheckDetail();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.componentID = r.string();
                    break;
                case 2:
                    m.type = r.string();
                    break;
                case 3:
                    m.observedValue = r.double();
                    break;
                case 4:
                    m.observedUnit = r.string();
                    break;
                case 5:
                    m.status = r.string();
                    break;
                case 6:
                    m.time = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                case 7:
                    m.output = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return HealthCheckDetail;
    })();

    common.NamedHealthCheckDetail = (function() {

        /**
         * Properties of a NamedHealthCheckDetail.
         * @memberof common
         * @interface INamedHealthCheckDetail
         * @property {string|null} [name] NamedHealthCheckDetail name
         * @property {Array.<common.IHealthCheckDetail>|null} [details] NamedHealthCheckDetail details
         */

        /**
         * Constructs a new NamedHealthCheckDetail.
         * @memberof common
         * @classdesc Represents a NamedHealthCheckDetail.
         * @implements INamedHealthCheckDetail
         * @constructor
         * @param {common.INamedHealthCheckDetail=} [p] Properties to set
         */
        function NamedHealthCheckDetail(p) {
            this.details = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * NamedHealthCheckDetail name.
         * @member {string} name
         * @memberof common.NamedHealthCheckDetail
         * @instance
         */
        NamedHealthCheckDetail.prototype.name = "";

        /**
         * NamedHealthCheckDetail details.
         * @member {Array.<common.IHealthCheckDetail>} details
         * @memberof common.NamedHealthCheckDetail
         * @instance
         */
        NamedHealthCheckDetail.prototype.details = $util.emptyArray;

        /**
         * Creates a new NamedHealthCheckDetail instance using the specified properties.
         * @function create
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.INamedHealthCheckDetail=} [properties] Properties to set
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail instance
         */
        NamedHealthCheckDetail.create = function create(properties) {
            return new NamedHealthCheckDetail(properties);
        };

        /**
         * Encodes the specified NamedHealthCheckDetail message. Does not implicitly {@link common.NamedHealthCheckDetail.verify|verify} messages.
         * @function encode
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {common.INamedHealthCheckDetail} m NamedHealthCheckDetail message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedHealthCheckDetail.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            if (m.details != null && m.details.length) {
                for (var i = 0; i < m.details.length; ++i)
                    $root.common.HealthCheckDetail.encode(m.details[i], w.uint32(18).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a NamedHealthCheckDetail message from the specified reader or buffer.
         * @function decode
         * @memberof common.NamedHealthCheckDetail
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.NamedHealthCheckDetail} NamedHealthCheckDetail
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedHealthCheckDetail.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.NamedHealthCheckDetail();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.name = r.string();
                    break;
                case 2:
                    if (!(m.details && m.details.length))
                        m.details = [];
                    m.details.push($root.common.HealthCheckDetail.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return NamedHealthCheckDetail;
    })();

    common.HealthCheck = (function() {

        /**
         * Properties of a HealthCheck.
         * @memberof common
         * @interface IHealthCheck
         * @property {string|null} [status] HealthCheck status
         * @property {string|null} [version] HealthCheck version
         * @property {string|null} [releaseID] HealthCheck releaseID
         * @property {Array.<string>|null} [notes] HealthCheck notes
         * @property {string|null} [output] HealthCheck output
         * @property {Array.<common.INamedHealthCheckDetail>|null} [details] HealthCheck details
         */

        /**
         * Constructs a new HealthCheck.
         * @memberof common
         * @classdesc Represents a HealthCheck.
         * @implements IHealthCheck
         * @constructor
         * @param {common.IHealthCheck=} [p] Properties to set
         */
        function HealthCheck(p) {
            this.notes = [];
            this.details = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HealthCheck status.
         * @member {string} status
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.status = "";

        /**
         * HealthCheck version.
         * @member {string} version
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.version = "";

        /**
         * HealthCheck releaseID.
         * @member {string} releaseID
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.releaseID = "";

        /**
         * HealthCheck notes.
         * @member {Array.<string>} notes
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.notes = $util.emptyArray;

        /**
         * HealthCheck output.
         * @member {string} output
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.output = "";

        /**
         * HealthCheck details.
         * @member {Array.<common.INamedHealthCheckDetail>} details
         * @memberof common.HealthCheck
         * @instance
         */
        HealthCheck.prototype.details = $util.emptyArray;

        /**
         * Creates a new HealthCheck instance using the specified properties.
         * @function create
         * @memberof common.HealthCheck
         * @static
         * @param {common.IHealthCheck=} [properties] Properties to set
         * @returns {common.HealthCheck} HealthCheck instance
         */
        HealthCheck.create = function create(properties) {
            return new HealthCheck(properties);
        };

        /**
         * Encodes the specified HealthCheck message. Does not implicitly {@link common.HealthCheck.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheck
         * @static
         * @param {common.IHealthCheck} m HealthCheck message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheck.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(10).string(m.status);
            if (m.version != null && Object.hasOwnProperty.call(m, "version"))
                w.uint32(18).string(m.version);
            if (m.releaseID != null && Object.hasOwnProperty.call(m, "releaseID"))
                w.uint32(26).string(m.releaseID);
            if (m.notes != null && m.notes.length) {
                for (var i = 0; i < m.notes.length; ++i)
                    w.uint32(34).string(m.notes[i]);
            }
            if (m.output != null && Object.hasOwnProperty.call(m, "output"))
                w.uint32(42).string(m.output);
            if (m.details != null && m.details.length) {
                for (var i = 0; i < m.details.length; ++i)
                    $root.common.NamedHealthCheckDetail.encode(m.details[i], w.uint32(50).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes a HealthCheck message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheck
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.HealthCheck} HealthCheck
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheck.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.HealthCheck();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.status = r.string();
                    break;
                case 2:
                    m.version = r.string();
                    break;
                case 3:
                    m.releaseID = r.string();
                    break;
                case 4:
                    if (!(m.notes && m.notes.length))
                        m.notes = [];
                    m.notes.push(r.string());
                    break;
                case 5:
                    m.output = r.string();
                    break;
                case 6:
                    if (!(m.details && m.details.length))
                        m.details = [];
                    m.details.push($root.common.NamedHealthCheckDetail.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return HealthCheck;
    })();

    common.HealthCheckResponse = (function() {

        /**
         * Properties of a HealthCheckResponse.
         * @memberof common
         * @interface IHealthCheckResponse
         * @property {common.IHealthCheck|null} [health] HealthCheckResponse health
         */

        /**
         * Constructs a new HealthCheckResponse.
         * @memberof common
         * @classdesc Represents a HealthCheckResponse.
         * @implements IHealthCheckResponse
         * @constructor
         * @param {common.IHealthCheckResponse=} [p] Properties to set
         */
        function HealthCheckResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * HealthCheckResponse health.
         * @member {common.IHealthCheck|null|undefined} health
         * @memberof common.HealthCheckResponse
         * @instance
         */
        HealthCheckResponse.prototype.health = null;

        /**
         * Creates a new HealthCheckResponse instance using the specified properties.
         * @function create
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.IHealthCheckResponse=} [properties] Properties to set
         * @returns {common.HealthCheckResponse} HealthCheckResponse instance
         */
        HealthCheckResponse.create = function create(properties) {
            return new HealthCheckResponse(properties);
        };

        /**
         * Encodes the specified HealthCheckResponse message. Does not implicitly {@link common.HealthCheckResponse.verify|verify} messages.
         * @function encode
         * @memberof common.HealthCheckResponse
         * @static
         * @param {common.IHealthCheckResponse} m HealthCheckResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HealthCheckResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.health != null && Object.hasOwnProperty.call(m, "health"))
                $root.common.HealthCheck.encode(m.health, w.uint32(10).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a HealthCheckResponse message from the specified reader or buffer.
         * @function decode
         * @memberof common.HealthCheckResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.HealthCheckResponse} HealthCheckResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HealthCheckResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.HealthCheckResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.health = $root.common.HealthCheck.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return HealthCheckResponse;
    })();

    common.Timeslot = (function() {

        /**
         * Properties of a Timeslot.
         * @memberof common
         * @interface ITimeslot
         * @property {google.protobuf.ITimestamp|null} [start] Timeslot start
         * @property {google.protobuf.ITimestamp|null} [end] Timeslot end
         */

        /**
         * Constructs a new Timeslot.
         * @memberof common
         * @classdesc Represents a Timeslot.
         * @implements ITimeslot
         * @constructor
         * @param {common.ITimeslot=} [p] Properties to set
         */
        function Timeslot(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Timeslot start.
         * @member {google.protobuf.ITimestamp|null|undefined} start
         * @memberof common.Timeslot
         * @instance
         */
        Timeslot.prototype.start = null;

        /**
         * Timeslot end.
         * @member {google.protobuf.ITimestamp|null|undefined} end
         * @memberof common.Timeslot
         * @instance
         */
        Timeslot.prototype.end = null;

        /**
         * Creates a new Timeslot instance using the specified properties.
         * @function create
         * @memberof common.Timeslot
         * @static
         * @param {common.ITimeslot=} [properties] Properties to set
         * @returns {common.Timeslot} Timeslot instance
         */
        Timeslot.create = function create(properties) {
            return new Timeslot(properties);
        };

        /**
         * Encodes the specified Timeslot message. Does not implicitly {@link common.Timeslot.verify|verify} messages.
         * @function encode
         * @memberof common.Timeslot
         * @static
         * @param {common.ITimeslot} m Timeslot message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Timeslot.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.start != null && Object.hasOwnProperty.call(m, "start"))
                $root.google.protobuf.Timestamp.encode(m.start, w.uint32(10).fork()).ldelim();
            if (m.end != null && Object.hasOwnProperty.call(m, "end"))
                $root.google.protobuf.Timestamp.encode(m.end, w.uint32(18).fork()).ldelim();
            return w;
        };

        /**
         * Decodes a Timeslot message from the specified reader or buffer.
         * @function decode
         * @memberof common.Timeslot
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Timeslot} Timeslot
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Timeslot.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Timeslot();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.start = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                case 2:
                    m.end = $root.google.protobuf.Timestamp.decode(r, r.uint32());
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Timeslot;
    })();

    common.ErrorResponse = (function() {

        /**
         * Properties of an ErrorResponse.
         * @memberof common
         * @interface IErrorResponse
         * @property {Array.<common.IError>|null} [errors] ErrorResponse errors
         */

        /**
         * Constructs a new ErrorResponse.
         * @memberof common
         * @classdesc Represents an ErrorResponse.
         * @implements IErrorResponse
         * @constructor
         * @param {common.IErrorResponse=} [p] Properties to set
         */
        function ErrorResponse(p) {
            this.errors = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ErrorResponse errors.
         * @member {Array.<common.IError>} errors
         * @memberof common.ErrorResponse
         * @instance
         */
        ErrorResponse.prototype.errors = $util.emptyArray;

        /**
         * Creates a new ErrorResponse instance using the specified properties.
         * @function create
         * @memberof common.ErrorResponse
         * @static
         * @param {common.IErrorResponse=} [properties] Properties to set
         * @returns {common.ErrorResponse} ErrorResponse instance
         */
        ErrorResponse.create = function create(properties) {
            return new ErrorResponse(properties);
        };

        /**
         * Encodes the specified ErrorResponse message. Does not implicitly {@link common.ErrorResponse.verify|verify} messages.
         * @function encode
         * @memberof common.ErrorResponse
         * @static
         * @param {common.IErrorResponse} m ErrorResponse message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ErrorResponse.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.errors != null && m.errors.length) {
                for (var i = 0; i < m.errors.length; ++i)
                    $root.common.Error.encode(m.errors[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Decodes an ErrorResponse message from the specified reader or buffer.
         * @function decode
         * @memberof common.ErrorResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.ErrorResponse} ErrorResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ErrorResponse.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.ErrorResponse();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.errors && m.errors.length))
                        m.errors = [];
                    m.errors.push($root.common.Error.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ErrorResponse;
    })();

    common.Error = (function() {

        /**
         * Properties of an Error.
         * @memberof common
         * @interface IError
         * @property {string|null} [message] Error message
         * @property {string|null} [code] Error code
         */

        /**
         * Constructs a new Error.
         * @memberof common
         * @classdesc Represents an Error.
         * @implements IError
         * @constructor
         * @param {common.IError=} [p] Properties to set
         */
        function Error(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Error message.
         * @member {string} message
         * @memberof common.Error
         * @instance
         */
        Error.prototype.message = "";

        /**
         * Error code.
         * @member {string} code
         * @memberof common.Error
         * @instance
         */
        Error.prototype.code = "";

        /**
         * Creates a new Error instance using the specified properties.
         * @function create
         * @memberof common.Error
         * @static
         * @param {common.IError=} [properties] Properties to set
         * @returns {common.Error} Error instance
         */
        Error.create = function create(properties) {
            return new Error(properties);
        };

        /**
         * Encodes the specified Error message. Does not implicitly {@link common.Error.verify|verify} messages.
         * @function encode
         * @memberof common.Error
         * @static
         * @param {common.IError} m Error message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Error.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.message != null && Object.hasOwnProperty.call(m, "message"))
                w.uint32(10).string(m.message);
            if (m.code != null && Object.hasOwnProperty.call(m, "code"))
                w.uint32(18).string(m.code);
            return w;
        };

        /**
         * Decodes an Error message from the specified reader or buffer.
         * @function decode
         * @memberof common.Error
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Error} Error
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Error.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Error();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.message = r.string();
                    break;
                case 2:
                    m.code = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Error;
    })();

    common.Money = (function() {

        /**
         * Properties of a Money.
         * @memberof common
         * @interface IMoney
         * @property {string|null} [currencyCode] Money currencyCode
         * @property {number|Long|null} [units] Money units
         * @property {number|null} [nanos] Money nanos
         */

        /**
         * Constructs a new Money.
         * @memberof common
         * @classdesc Represents a Money.
         * @implements IMoney
         * @constructor
         * @param {common.IMoney=} [p] Properties to set
         */
        function Money(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Money currencyCode.
         * @member {string} currencyCode
         * @memberof common.Money
         * @instance
         */
        Money.prototype.currencyCode = "";

        /**
         * Money units.
         * @member {number|Long} units
         * @memberof common.Money
         * @instance
         */
        Money.prototype.units = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Money nanos.
         * @member {number} nanos
         * @memberof common.Money
         * @instance
         */
        Money.prototype.nanos = 0;

        /**
         * Creates a new Money instance using the specified properties.
         * @function create
         * @memberof common.Money
         * @static
         * @param {common.IMoney=} [properties] Properties to set
         * @returns {common.Money} Money instance
         */
        Money.create = function create(properties) {
            return new Money(properties);
        };

        /**
         * Encodes the specified Money message. Does not implicitly {@link common.Money.verify|verify} messages.
         * @function encode
         * @memberof common.Money
         * @static
         * @param {common.IMoney} m Money message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Money.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.currencyCode != null && Object.hasOwnProperty.call(m, "currencyCode"))
                w.uint32(10).string(m.currencyCode);
            if (m.units != null && Object.hasOwnProperty.call(m, "units"))
                w.uint32(16).int64(m.units);
            if (m.nanos != null && Object.hasOwnProperty.call(m, "nanos"))
                w.uint32(24).int32(m.nanos);
            return w;
        };

        /**
         * Decodes a Money message from the specified reader or buffer.
         * @function decode
         * @memberof common.Money
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Money} Money
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Money.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Money();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.currencyCode = r.string();
                    break;
                case 2:
                    m.units = r.int64();
                    break;
                case 3:
                    m.nanos = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Money;
    })();

    common.Date = (function() {

        /**
         * Properties of a Date.
         * @memberof common
         * @interface IDate
         * @property {number|null} [year] Date year
         * @property {number|null} [month] Date month
         * @property {number|null} [day] Date day
         */

        /**
         * Constructs a new Date.
         * @memberof common
         * @classdesc Represents a Date.
         * @implements IDate
         * @constructor
         * @param {common.IDate=} [p] Properties to set
         */
        function Date(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Date year.
         * @member {number} year
         * @memberof common.Date
         * @instance
         */
        Date.prototype.year = 0;

        /**
         * Date month.
         * @member {number} month
         * @memberof common.Date
         * @instance
         */
        Date.prototype.month = 0;

        /**
         * Date day.
         * @member {number} day
         * @memberof common.Date
         * @instance
         */
        Date.prototype.day = 0;

        /**
         * Creates a new Date instance using the specified properties.
         * @function create
         * @memberof common.Date
         * @static
         * @param {common.IDate=} [properties] Properties to set
         * @returns {common.Date} Date instance
         */
        Date.create = function create(properties) {
            return new Date(properties);
        };

        /**
         * Encodes the specified Date message. Does not implicitly {@link common.Date.verify|verify} messages.
         * @function encode
         * @memberof common.Date
         * @static
         * @param {common.IDate} m Date message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Date.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.year != null && Object.hasOwnProperty.call(m, "year"))
                w.uint32(8).int32(m.year);
            if (m.month != null && Object.hasOwnProperty.call(m, "month"))
                w.uint32(16).int32(m.month);
            if (m.day != null && Object.hasOwnProperty.call(m, "day"))
                w.uint32(24).int32(m.day);
            return w;
        };

        /**
         * Decodes a Date message from the specified reader or buffer.
         * @function decode
         * @memberof common.Date
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Date} Date
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Date.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Date();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.year = r.int32();
                    break;
                case 2:
                    m.month = r.int32();
                    break;
                case 3:
                    m.day = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Date;
    })();

    common.GeoLocation = (function() {

        /**
         * Properties of a GeoLocation.
         * @memberof common
         * @interface IGeoLocation
         * @property {number|null} [latitude] GeoLocation latitude
         * @property {number|null} [longitude] GeoLocation longitude
         */

        /**
         * Constructs a new GeoLocation.
         * @memberof common
         * @classdesc Represents a GeoLocation.
         * @implements IGeoLocation
         * @constructor
         * @param {common.IGeoLocation=} [p] Properties to set
         */
        function GeoLocation(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * GeoLocation latitude.
         * @member {number} latitude
         * @memberof common.GeoLocation
         * @instance
         */
        GeoLocation.prototype.latitude = 0;

        /**
         * GeoLocation longitude.
         * @member {number} longitude
         * @memberof common.GeoLocation
         * @instance
         */
        GeoLocation.prototype.longitude = 0;

        /**
         * Creates a new GeoLocation instance using the specified properties.
         * @function create
         * @memberof common.GeoLocation
         * @static
         * @param {common.IGeoLocation=} [properties] Properties to set
         * @returns {common.GeoLocation} GeoLocation instance
         */
        GeoLocation.create = function create(properties) {
            return new GeoLocation(properties);
        };

        /**
         * Encodes the specified GeoLocation message. Does not implicitly {@link common.GeoLocation.verify|verify} messages.
         * @function encode
         * @memberof common.GeoLocation
         * @static
         * @param {common.IGeoLocation} m GeoLocation message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GeoLocation.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.latitude != null && Object.hasOwnProperty.call(m, "latitude"))
                w.uint32(13).float(m.latitude);
            if (m.longitude != null && Object.hasOwnProperty.call(m, "longitude"))
                w.uint32(21).float(m.longitude);
            return w;
        };

        /**
         * Decodes a GeoLocation message from the specified reader or buffer.
         * @function decode
         * @memberof common.GeoLocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.GeoLocation} GeoLocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GeoLocation.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.GeoLocation();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.latitude = r.float();
                    break;
                case 2:
                    m.longitude = r.float();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return GeoLocation;
    })();

    /**
     * Locale enum.
     * @name common.Locale
     * @enum {number}
     * @property {number} EN=0 EN value
     * @property {number} ES=1 ES value
     */
    common.Locale = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EN"] = 0;
        values[valuesById[1] = "ES"] = 1;
        return values;
    })();

    common.Range_U32 = (function() {

        /**
         * Properties of a Range_U32.
         * @memberof common
         * @interface IRange_U32
         * @property {number|null} [min] Range_U32 min
         * @property {number|null} [max] Range_U32 max
         */

        /**
         * Constructs a new Range_U32.
         * @memberof common
         * @classdesc Represents a Range_U32.
         * @implements IRange_U32
         * @constructor
         * @param {common.IRange_U32=} [p] Properties to set
         */
        function Range_U32(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * Range_U32 min.
         * @member {number} min
         * @memberof common.Range_U32
         * @instance
         */
        Range_U32.prototype.min = 0;

        /**
         * Range_U32 max.
         * @member {number} max
         * @memberof common.Range_U32
         * @instance
         */
        Range_U32.prototype.max = 0;

        /**
         * Creates a new Range_U32 instance using the specified properties.
         * @function create
         * @memberof common.Range_U32
         * @static
         * @param {common.IRange_U32=} [properties] Properties to set
         * @returns {common.Range_U32} Range_U32 instance
         */
        Range_U32.create = function create(properties) {
            return new Range_U32(properties);
        };

        /**
         * Encodes the specified Range_U32 message. Does not implicitly {@link common.Range_U32.verify|verify} messages.
         * @function encode
         * @memberof common.Range_U32
         * @static
         * @param {common.IRange_U32} m Range_U32 message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Range_U32.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.min != null && Object.hasOwnProperty.call(m, "min"))
                w.uint32(8).uint32(m.min);
            if (m.max != null && Object.hasOwnProperty.call(m, "max"))
                w.uint32(16).uint32(m.max);
            return w;
        };

        /**
         * Decodes a Range_U32 message from the specified reader or buffer.
         * @function decode
         * @memberof common.Range_U32
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.Range_U32} Range_U32
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Range_U32.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.Range_U32();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.min = r.uint32();
                    break;
                case 2:
                    m.max = r.uint32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return Range_U32;
    })();

    common.ApplicationSource = (function() {

        /**
         * Properties of an ApplicationSource.
         * @memberof common
         * @interface IApplicationSource
         * @property {string|null} [name] ApplicationSource name
         */

        /**
         * Constructs a new ApplicationSource.
         * @memberof common
         * @classdesc Represents an ApplicationSource.
         * @implements IApplicationSource
         * @constructor
         * @param {common.IApplicationSource=} [p] Properties to set
         */
        function ApplicationSource(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * ApplicationSource name.
         * @member {string} name
         * @memberof common.ApplicationSource
         * @instance
         */
        ApplicationSource.prototype.name = "";

        /**
         * Creates a new ApplicationSource instance using the specified properties.
         * @function create
         * @memberof common.ApplicationSource
         * @static
         * @param {common.IApplicationSource=} [properties] Properties to set
         * @returns {common.ApplicationSource} ApplicationSource instance
         */
        ApplicationSource.create = function create(properties) {
            return new ApplicationSource(properties);
        };

        /**
         * Encodes the specified ApplicationSource message. Does not implicitly {@link common.ApplicationSource.verify|verify} messages.
         * @function encode
         * @memberof common.ApplicationSource
         * @static
         * @param {common.IApplicationSource} m ApplicationSource message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ApplicationSource.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                w.uint32(10).string(m.name);
            return w;
        };

        /**
         * Decodes an ApplicationSource message from the specified reader or buffer.
         * @function decode
         * @memberof common.ApplicationSource
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {common.ApplicationSource} ApplicationSource
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ApplicationSource.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.common.ApplicationSource();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.name = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        return ApplicationSource;
    })();

    return common;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [p] Properties to set
             */
            function Timestamp(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} m Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.seconds != null && Object.hasOwnProperty.call(m, "seconds"))
                    w.uint32(8).int64(m.seconds);
                if (m.nanos != null && Object.hasOwnProperty.call(m, "nanos"))
                    w.uint32(16).int32(m.nanos);
                return w;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.google.protobuf.Timestamp();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.seconds = r.int64();
                        break;
                    case 2:
                        m.nanos = r.int32();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
