import axios from 'axios'

interface Config {
  ENV: string
  APP_NAME: string
  AZURE_CLIENT_ID: string
  TENANT: string
  CSS_FILE_NAME_AHS: string
  BASE_URL: string
  PROCONNECT_PATH: string
}

class Api {
  public idToken: string = ''
  public config: Config = {
    // defaults for local
    ENV: process.env.REACT_APP_ENV,
    APP_NAME: process.env.REACT_APP_NAME,
    AZURE_CLIENT_ID: process.env.REACT_APP_AZURE_CLIENT_ID,
    TENANT: process.env.REACT_APP_TENANT,
    CSS_FILE_NAME_AHS: process.env.REACT_APP_CSS_FILE_NAME_AHS,
    BASE_URL: process.env.REACT_APP_BASE_URL,
    PROCONNECT_PATH: process.env.REACT_APP_PROCONNECT_PATH
  }

  async loadConfig() {
    try {
      const { data } = await axios.get<Config>('/config')
      this.config = data
    } catch (err) {
      console.warn(
        'There was an error loading the config via nginx, defaulting to local'
      )
    }
  }
}


export default new Api()
