import {
  Text,
  ProconnectLogoMono,
} from '@ftdr/blueprint-components-react'
import { useAuth } from '@ftdr/use-auth'
import { NavLink } from 'react-router-dom'

export const NavBar = () => {
  const { oidcUser } = useAuth()

  const homeUrl = '/'

  return (
    <>
      <div className="bg-primary-800 flex h-20 items-center px-4 lg:px-12">
        <NavLink
          to={homeUrl}
          className="mr-auto ml-6 flex flex-col items-center"
        >
          <ProconnectLogoMono height={50} width={150} className="text-white"/>
        </NavLink>
          <div className="flex items-center">
            <Text
              color="white"
              className="ml-6 hidden lg:block"
            >
              {oidcUser?.profile.name}
            </Text>
          </div>
      </div>
    </>
  )
}
